<template>
    <div class="grid">
        <div class="col-8">
            <div class="card">
                <h5>{{ fornecedor }} - {{ mes_referencia }} - {{ status }}</h5>
            </div>
        </div>
        <div class="col-4">
            <div class="card" style="text-align: center;">
                <Button class="ml-3 p-button-success" :loading="loading" @click="validar()">Validar</Button>
                <Button class="ml-3 p-button-danger" :loading="loading" @click="rejeitar()">Rejeitar</Button>
            </div>
        </div>
    </div>

    <div class="grid">
        <div class="col-12">


            <div class="card">

                <DataTable tableStyle="min-width: 50rem" ref="dt" exportFilename="relatorio[BASE1]" csvSeparator=";"
                    editMode="cell" @cell-edit-complete="onCellEditComplete" tableClass="editable-cells-table"
                    :value="relatorios" dataKey="id" :paginator="true" :rows="50" :filters="filters" :loading="loading"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 25, 50, 100, 200]" stripedRows scrollable scrollHeight="600px"
                    currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
                    responsiveLayout="scroll">
                    <template #header>
                        <div class="
                  flex flex-column
                  md:flex-row md:justify-content-between md:align-items-center
                ">
                            <h5 class="m-0">Planilha</h5>

                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Buscar" />
                                <Button v-if="mostrar_link" class="ml-3 p-button-primary" :loading="loading"
                                    @click="baixarZip()">Baixar Faturas</Button>
                            </span>

                        </div>
                    </template>
                  
                    <Column frozen field="site_id_tim" header="Site ID TIM" footer="Site ID TIM" style="min-width: 200px">
                    </Column>

                    <Column frozen field="endereco_id_tim" header="END ID TIM" footer="END ID TIM" style="min-width: 200px">
                    </Column>

                    <Column field="regional_tim" header="Regional TIM" footer="Regional TIM" style="min-width: 200px">
                    </Column>

                    <Column field="uf" header="UF" footer="UF" style="min-width: 100px"></Column>

                    <Column field="detentora" header="Detentora" footer="Detentora" style="min-width: 200px"></Column>

                    <Column field="site_id_detentora" header="Site ID Detentora" footer="Site ID Detentora"
                        style="min-width: 200px"></Column>

                    <Column field="data_rfi" header="Data RFI" footer="Data RFI" style="min-width: 200px"></Column>

                    <Column field="tipo_de_energia" header="Tipo de Energia" footer="Tipo de Energia"
                        style="min-width: 200px"></Column>

                    <Column field="consumo_kWh" header="Consumo (KWh)" footer="Consumo (KWh)" style="min-width: 200px">
                    </Column>

                    <Column field="e_viavel_instalacao_de_medidor_tim" header="É viável Instalação de Medidor TIM?"
                        footer="É viável Instalação de Medidor TIM?" style="min-width: 300px"></Column>

                    <Column field="justificativa" header="Justificativa" footer="Justificativa" style="min-width: 600px">
                    </Column>

                    <Column field="mes_de_referencia_do_formulario" header="Mês de Referência do Formulário"
                        footer="Mês de Referência do Formulário" style="min-width: 300px"></Column>

                    <Column field="data_de_envio_do_formulario" header="Data de envio do formulário"
                        footer="Data de envio do formulário" style="min-width: 300px"></Column>

                    <Column field="mes_de_Referencia_da_fatura" header="Mês de Referência da Fatura"
                        footer="Mês de Referência da Fatura" style="min-width: 300px"></Column>

                    <Column field="valor_total_fatura" header="Valor Total Fatura (R$)" footer="Valor Total Fatura (R$)"
                        style="min-width: 200px"></Column>

                    <Column field="concatenado" header="Concatenado (END_ID + Dententora)"
                        footer="Concatenado (END_ID + Dententora)" style="min-width: 300px"></Column>

                    <Column field="status_base_cadastral" header="Status Base Cadastral" footer="Status Base Cadastral"
                        style="min-width: 200px"></Column>

                    <Column field="status_sge" header="Status SGE" footer="Status SGE" style="min-width: 200px">
                    </Column>

                    <Column field="data_entrante_sge" header="Data Entrante SGE" footer="Data Entrante SGE"
                        style="min-width: 200px"></Column>

                    <Column field="status_titularidade" header="Status Titularidade" footer="Status Titularidade"
                        style="min-width: 200px"></Column>

                    <Column field="status_site_opm" header="Status Site OPM" footer="Status Site OPM"
                        style="min-width: 200px"></Column>

                    <Column field="etapa_atual_netflow" header="Etapa Atual Netflow" footer="Etapa Atual Netflow"
                        style="min-width: 200px"></Column>

                    <Column field="classificacao_do_reembolso" header="Classificação do Reembolso"
                        footer="Classificação do Reembolso" style="min-width: 300px"></Column>

                    <Column field="motivo_da_contestacao" header="Motivo da Contestação" footer="Motivo da Contestação"
                        style="min-width: 300px">
                        <template #body="{ data, field }">
                            {{ data[field] }}
                        </template>
                        <template #editor="{ data, field }">
                            <Dropdown id="id_status" v-model="data[field]" :options="motivos_contestacao"
                                optionLabel="label" optionValue="value" placeholder="Selecionar o Motivo"
                                class="mr-2 ml-5 mt-2">
                            </Dropdown>
                        </template>
                    </Column>



                    <Column field="valor_contestado" header="Valor Contestado (R$)" style="min-width: 200px">
                        <template #body="{ data, field }">
                            {{ data[field] }}
                        </template>
                        <template #editor="{ data, field }">
                            <InputText v-model="data[field]" />
                        </template>
                    </Column>

                    <Column field="valor_validado" header="Valor Validado (R$)" footer="Valor Validado (R$)"
                        style="min-width: 200px">
                        <template #body="{ data, field }">
                            {{ data[field] }}
                        </template>
                        <template #editor="{ data, field }">
                            <InputText v-model="data[field]" />
                        </template>
                    </Column>

                    <Column field="status" header="Status" footer="Status" style="min-width: 200px">
                        <template #body="{ data, field }">
                            {{ data[field] }}
                        </template>
                        <template #editor="{ data, field }">


                            <Dropdown id="id_status" v-model="data[field]" :options="status_contestado" optionLabel="label"
                                optionValue="value" placeholder="Selecionar o Status" class="mr-2 ml-5 mt-2">
                            </Dropdown>
                        </template>
                    </Column>

                    <Column field="data_entrante_sge_ajustada" header="Data da validação" footer="Data da validação"
                        style="min-width: 200px">

                    </Column>

                    <Column v-if="mostrar_link" field="link" header="Link" footer="Link" style="min-width: 600px"></Column>
                </DataTable>
            </div>



        </div>
    </div>
</template>
  
<script>
import { FilterMatchMode } from "primevue/api";

export default {
    data() {
        return {
            status_contestado: [
                { label: "Ok", value: "Ok" },
                { label: "Contestado", value: "Contestado" },
            ],
            motivos_contestacao: [
                { label: "Medidor Próprio TIM", value: "Medidor Próprio TIM" },
                { label: "Medidor Próprio | Cobrança Reativa", value: "Medidor Próprio | Cobrança Reativa" },
                { label: "Site em aquisição ou Obra", value: "Site em aquisição ou Obra" },
                { label: "Outros", value: "Outros" },
            ],
            loading: true,
            editingRows: [],
            mostrarPlanilhaModal: false,
            planilhas: [],
            relatorios: [],
            productDialog: false,
            deleteProductDialog: false,
            deleteProductsDialog: false,
            empresa: {},
            selectedProducts: null,
            filters: {},
            submitted: false,
            status: "",
            statuses: [
                { label: "Inativo", value: "0" },
                { label: "Ativo", value: "1" },
            ],
            fornecedor: "",
            mes_referencia: "",
            mostrar_link: false,

        };
    },
    productService: null,
    created() {
        this.initFilters();
    },

    mounted() {
        this.listarRelatorio();

        //this.productService.getProducts().then((data) => (this.products = data));
    },
    methods: {
        baixarZip(){
            window.open('https://compartilhamento.sgetim.com.br/api/public/api/download_zip/'+this.$route.params.id);
            //window.open('http://127.0.0.1:8000/api/download_zip/'+this.$route.params.id);
            //window.open('/app/front/faturas.zip');
        },
        validar() {
            this.loading = true;
            let dados = {
                id: this.$route.params.id,
                id_status: 1
            }
            this.axios
                .post("/fornecedores/salvarstatus", dados)
                .then(() => {
                    this.mostrarPlanilhaModal = false;
                    this.$toast.add({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Status atualizado com sucesso.",
                        life: 3000,
                    });
                    this.listarRelatorio();
                })
                .catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    });
                });
        },
        rejeitar() {
            this.loading = true;
            let dados = {
                id: this.$route.params.id,
                id_status: 2
            }
            this.axios
                .post("/fornecedores/salvarstatus", dados)
                .then(() => {
                    this.mostrarPlanilhaModal = false;
                    this.$toast.add({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Status atualizado com sucesso.",
                        life: 3000,
                    });
                    this.listarRelatorio();
                })
                .catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    });
                });
        },
        onCellEditComplete(event) {
            let { data, newValue, field } = event;

            this.loading = true;

            let dados = {
                campo: field,
                valor: newValue,
                id: data.id_consolidado
            }

            console.log(data)

            this.axios
                .post("/fornecedores/contestar", dados)
                .then(() => {

                    const indexToUpdate = this.relatorios.findIndex(item => item.id === dados.id);
                    this.relatorios[indexToUpdate][field] = newValue;
                    this.loading = false;

                })
                .catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    });
                });

        },
        mostrarPlanilha(planilha) {
            this.listarRelatorio(planilha.id);
            this.mostrarPlanilhaModal = true;
        },
        listarRelatorio() {
            this.loading = true;
            let dados = {
                id_planilha: this.$route.params.id
            }

            this.axios
                .post("/relatorios/consolidado", dados)
                .then((response) => {
                    console.log(response);

                    this.relatorios = response.data.dados;
                    this.fornecedor = response.data.planilha[0].razao_social
                    this.mes_referencia = response.data.planilha[0].mes_referencia
                    this.status = response.data.planilha[0].status

                    if (response.data.tipo_faturamento == 2) {
                        this.mostrar_link = false;
                       
                    }
                    else {
          
                        this.mostrar_link = true;
                    }
                    this.loading = false;
                })
                .catch(() => {
                    self.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    });
                });
        },

        listarPlanilhas() {
            this.axios
                .post("/relatorios/planilhas-importadas")
                .then((response) => {
                    console.log(response);
                    this.planilhas = response.data.dados
                })
                .catch(() => {
                    self.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    });
                });
        },

        findIndexById(id) {
            let index = -1;
            for (let i = 0; i < this.products.length; i++) {
                if (this.products[i].id === id) {
                    index = i;
                    break;
                }
            }
            return index;
        },
        createId() {
            let id = "";
            var chars =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            for (var i = 0; i < 5; i++) {
                id += chars.charAt(Math.floor(Math.random() * chars.length));
            }
            return id;
        },
        exportCSV() {
            this.$refs.dt.exportCSV();
        },

        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
    },
};
</script>
<style>
.p-datatable-thead,
.p-datatable-tbody {

    white-space: nowrap !important;
    min-width: 200px;

}
</style>